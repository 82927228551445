











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab4Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'The limiting reagent spot no longer appears in the reaction mixture',
          value: 'LrSpotGone',
        },
        {
          text: 'There is a new spot showing in the reaction mixture lane, suggesting the formation of a new product',
          value: 'newSpotForProduct',
        },
        {
          text: 'All reagent spots no longer appear in the reaction mixture',
          value: 'allReagentSpotsGone',
        },
      ],
      optionsFr: [
        {
          text: "La tache du réactif n'apparaît plus dans le mélange réactionnel",
          value: 'LrSpotGone',
        },
        {
          text: "Il y a une nouvelle tache pour le mélange réactionnel, ce qui suggère la formation d'un nouvel produit",
          value: 'newSpotForProduct',
        },
        {
          text: "Toutes les taches de tous les réactifs n'apparaissent plus dans le mélange réactionnel",
          value: 'allReagentSpotsGone',
        },
      ],
    };
  },
};
